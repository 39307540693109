.how-to-cook {
    padding: 50px;
    width: 100%;
    height: calc(100vh - 985px);
    display: flex;
    align-items: center;
    justify-content: center;

    &__curtain-img { 
        background-position: 0% 76%;
    }

    @media screen and (max-width: 1199px) {
        height: calc(100vh - 618px);
    }
}
